import PropTypes from 'prop-types';
import StarRatingDisplay from "../StarRatingDisplay";
import Button from '../Inputs/Button';
import useWindowWidth from '../../hooks/useWindowWidth';

const propTypes = {
    firstName: PropTypes.string.isRequired,
    onStarRatingClick: PropTypes.func.isRequired,
    ratings: PropTypes.array.isRequired,
};

const WidgetReviewStarRatings = ({ firstName, onStarRatingClick, ratings }) => {
    const width = useWindowWidth()

    return (
        <div className='flex flex-col'>
            <p className='font-bold m-0 mb-1 px-1'>
                {firstName}'s reviews
            </p>

            <div className='flex flex-col items-start max-xs:items-center max-xs:gap-1'>
                <div className='flex flex-col items-start'>
                    {ratings.sort((a, b) => b.type - a.type).map(rating =>
                        <Button
                            key={rating.type}
                            onClick={onStarRatingClick(rating.type)}
                            className={'bg-transparent shadow-none hover:bg-very-pale-mint-green px-1'}
                            text={
                                <>
                                    <StarRatingDisplay rating={rating.type} pixelSize={width < 600 ? '20px' : '25px'} />

                                    <p className='font-bold m-0'>
                                        ( {rating.count} )
                                    </p>
                                </>
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

WidgetReviewStarRatings.propTypes = propTypes;

export default WidgetReviewStarRatings;
