import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import ProfileClientReviews from './ProfileClientReviews';
import ProfilePanel from './ProfilePanel';
import WidgetReviewStarRatings from './WidgetReviewStarRatings';
import BreadcrumbsProfilePage from '../../components/Breadcrumbs/BreadcrumbsProfilePage';
import { formatPlural } from '../../utils/utilities';
import { getWidgetReviewsMetaData } from '../../data/metaTagsHelper';
import { getServiceLabelBy } from '../../data/attributeHelper';
import { FINANCIAL_ADVISER } from '../../__constants__';
import PageHead from '../../components/PageHead';
import { logoWhite } from '../../assets/images';
import { fetchReviews } from '../../dataFetchHelpers/profileFetchHelper';
import ToTopButton from '../Inputs/ToTopButton';
import Button from '../Inputs/Button';
import LoadingSpinner from '../vectors/LoadingSpinner';

function WidgetReviewsPageContent(props) {
    const [reviews, setReviews] = useState(props.reviews)
    const [filteredType, setFilteredType] = useState(null)
    const [offset, setOffset] = useState(0)
    const [hasMoreReviews, setHasMoreReviews] = useState(true)
    const [filterChanged, setFilterChanged] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingNext, setLoadingNext] = useState(false)
    const [initialPageLoad, setInitialPageLoad] = useState(true)

    const observer = useRef()
    const API_REVIEW_LIMIT = 5

    const loadNextReviews = useCallback(async () => {
        const nextReviews = await fetchReviews(props.professionalId, offset, API_REVIEW_LIMIT, filteredType)

        setLoading(false)
        setLoadingNext(false)

        if (nextReviews.length === 0) {
            setHasMoreReviews(false)
            return
        }

        if (reviews.length === 0) {
            setReviews([...nextReviews])
            return
        }

        setReviews(previousReviews => [...previousReviews, ...nextReviews])
    }, [offset, filteredType, filterChanged])

    useEffect(() => {
        if (initialPageLoad) {
            setInitialPageLoad(false)
            return;
        }

        if (filterChanged) {
            setHasMoreReviews(true)
            setFilterChanged(false)
            setReviews([])
            setLoading(true)
            return;
        }

        if (hasMoreReviews) {
            setLoadingNext(true)
            loadNextReviews()
            return;
        }
    }, [loadNextReviews, hasMoreReviews, filterChanged])

    const lastReviewRef = useCallback(domNode => {
        if (!hasMoreReviews) {
            return;
        }

        if (observer.current) {
            observer.current.disconnect()
        }

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                setOffset(previousOffset => previousOffset + API_REVIEW_LIMIT)
            }
        })

        if (domNode) {
            observer.current.observe(domNode)
        }
    }, [hasMoreReviews])

    const getReviewAverageScores = scores => {
        if (!scores) {
            return [
                { type: 5, count: 0 },
                { type: 4, count: 0 },
                { type: 3, count: 0 },
                { type: 2, count: 0 },
                { type: 1, count: 0 },
            ]
        }

        return Object.entries(scores).map(([rating, count]) => ({
            type: Number(rating),
            count,
        }))
    }

    const onStarRatingClick = type => e => {
        if (filteredType === type) return;

        setFilteredType(type)
        setOffset(0)
        setFilterChanged(true)
    }

    const showAllReviews = () => {
        setOffset(0)
        setFilteredType(null)
        setFilterChanged(true)
    }

    const {
        profileData,
        servicesSummary,
        savingsSummary,
        reviewsTotalCount,
        averageScores
    } = props;

    return (
        <>
            {profileData &&
                <PageHead {...getWidgetReviewsMetaData(profileData, reviewsTotalCount)} >
                    <meta property='og:image' content={logoWhite} />
                </PageHead>
            }

            <div className='m-4 max-xs:m-2'>
                <BreadcrumbsProfilePage
                    region={profileData.permalink.region}
                    city={profileData.permalink.city}
                    town={profileData.permalink.town}
                    vertical_id={profileData.vertical_id}
                    professionalName={profileData.full_name}
                />
            </div>

            <div className='mb-6 w-full flex flex-wrap box-border'>
                <div className='grow-0 md:basis-5/12 md:max-w-[41.666667%] max-w-full basis-full m-0 box-border'>
                    <ProfilePanel data={profileData} showButtons />
                </div>

                <div className='grow-0 md:basis-7/12 md:max-w-[58.333333%] basis-full max-w-full m-0 box-border'>
                    <div className='w-full m-0 justify-around flex flex-wrap box-border max-sm:mt-5'>
                        <div className='grow-0 sm:max-w-[25%] sm:basis-1/4 xs:max-w-[33.333333%] xs:basis-1/3 max-w-full basis-full m-0 box-border max-xs:text-center'>
                            <WidgetReviewStarRatings
                                firstName={profileData.first_name}
                                ratings={getReviewAverageScores(averageScores)}
                                onStarRatingClick={onStarRatingClick}
                            />
                        </div>

                        {profileData.vertical === FINANCIAL_ADVISER &&
                            <>
                                <div className='grow-0 max-xs:mt-5 xs:max-w-[33.333333%] xs:basis-1/3 max-w-[50%] basis-1/2 m-0 box-border'>
                                    <h6 className='font-bold text-base m-0'>Reviewed for</h6>

                                    {servicesSummary &&
                                        Object.entries(servicesSummary).map(([key, count]) =>
                                            <p className='m-0 text-sm' key={key}>
                                                {`${getServiceLabelBy('id', 5, key)} ( ${count} )`}
                                            </p>
                                        )
                                    }
                                </div>

                                <div className='max-xs:mt-5'>
                                    <h6 className='font-bold text-base m-0'>Reviewers' assets</h6>

                                    {savingsSummary &&
                                        Object.entries(savingsSummary).map(([key, count]) =>
                                            <p className='m-0 text-sm' key={key}>
                                                {`${key} ( ${count} )`}
                                            </p>
                                        )
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>

            <ProfileClientReviews
                ref={lastReviewRef}
                profileData={profileData}
                reviews={reviews}
                reviewsTotalCount={reviewsTotalCount}
                profileURL={profileData.permalink.url}
                showReadMoreButton={false}
                hasReviews={!isEmpty(reviews)}
                loading={loading}
                profileName={profileData.first_name}
            >
                {filteredType !== null &&
                    <div className='text-center mb-2'>
                        <h6 className='mb-4 text-lg m-0'>
                            {`Showing results for ${profileData.first_name} with ${filteredType} ${formatPlural(filteredType, 'star', 'stars')}`}
                        </h6>

                        <Button
                            onClick={showAllReviews}
                            text='Show all reviews'
                        />
                    </div>
                }
            </ProfileClientReviews>

            {loadingNext && !loading &&
                <div className='text-center [&_svg]:w-14 [&_svg]:h-14 mb-6'>
                    <LoadingSpinner />
                </div>
            }
            
            <ToTopButton />
        </>
    );
}

export default WidgetReviewsPageContent;
