import PageWrapper from '../../../../src/containers/PageWrapper';
import WidgetReviewsPageContent from '../../../../src/components/Profile/WidgetReviewsPageContent';
import { getProfessionalId } from '../../../../src/data/getUrlParams';
import { fetchProfile, fetchInitialReviewData } from '../../../../src/dataFetchHelpers/profileFetchHelper';
import { URL_COMPONENTS } from '../../../../src/__constants__';
import { isProfessionalTypeInUrlCorrect, redirect } from '../../../../src/utils/urlHelpers';


const WidgetReviewsPage = (props) => {
    return (
        <PageWrapper noPadding>
            <WidgetReviewsPageContent
                professionalId={props.professionalId}
                profileData={props.professional}
                reviews={props.reviewData.reviews}
                reviewsTotalCount={props.reviewData.reviewsTotalCount}
                servicesSummary={props.reviewData.servicesSummary}
                savingsSummary={props.reviewData.savingsSummary}
                averageScores={props.reviewData.averageScores}
            />
        </PageWrapper>
    );
};

export async function getServerSideProps(props) {
    const { params, res } = props;
    if ( !isProfessionalTypeInUrlCorrect(URL_COMPONENTS, params.type) ) return redirect()   

    const id = getProfessionalId(params.professionalPermalink)
    const profileData = await fetchProfile(id, true)
    if (!profileData || profileData.elevation_private_mode === true) {
        return redirect()
    }

    if (`${params.type}/${params.location}/${params.professionalPermalink}` !== profileData.permalink.url) {
        res.writeHead(301, { Location: '/' + profileData.permalink.url + '/reviews'})
        res.end()
    }

    const reviewData = await fetchInitialReviewData(id, true);

    return { props: {professionalId: id, professional: profileData, reviewData: reviewData } }
}

export default WidgetReviewsPage;
