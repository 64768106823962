import { useEffect, useState } from "react"

export default function ToTopButton() {
  const [isVisible, setIsVisible] = useState(false)

  const handleScroll = () => {
    const PIXELS_FROM_TOP = 500
    
    if (document.body.scrollTop > PIXELS_FROM_TOP || document.documentElement.scrollTop > PIXELS_FROM_TOP) {
      setIsVisible(true)
      return
    }

    setIsVisible(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  if (!isVisible) {
    return null
  }

  return (
    <div
      onClick={scrollToTop}
      class="!fixed bottom-8 end-5 rounded-full bg-primary-blue p-4 text-xs font-medium uppercase cursor-pointer leading-tight text-white transition duration-150 ease-in-out hover:bg-secondary-blue focus:bg-secondary-blue focus:shadow-lg focus:outline-none ring-0 active:bg-secondary-blue"
      id="btn-back-to-top"
    >
      <span class="[&>svg]:w-4">
          <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="3"
          stroke="currentColor">
          <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18" />
          </svg>
      </span>
    </div>
  )
}